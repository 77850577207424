<div *ngIf="gameSession && gameSession.state === 'lobby'" id="game-session-lobby">
  <h1>Játékosok</h1>
  <p *ngFor="let player of gameSession.players">{{player.user.name}}</p>
  <button *ngIf="gameSession?.state === 'lobby'" (click)="start()">{{'lobby_start' | translate}} <span *ngIf="gameSession.players.length < 4">{{ 'with_bots' | translate: { botCount: 4 - gameSession.players.length } }}</span></button>
</div>
<div *ngIf="game && playersRotated" id=game-session>
  <div id=upper-half>
    <div id="action-list" class="game-session-vertical-box" appAutoScroll>
      <ng-template ngFor let-action [ngForOf]="actions" let-i="index">
        <h2 *ngIf="!actions[i - 1]?.action?.startsWith('bid:') && action.action.startsWith('bid:')">{{'message_bidding' | translate}}</h2>
        <ng-container *ngIf="!actions[i - 1]?.action?.startsWith('fold:') && action.action.startsWith('fold:')">
          <h2>{{'message_folding' | translate}}</h2>
          <ng-container *ngFor="let p of game.players">
            <p *ngIf="p.tarockFoldCount">{{p.user.name}} {{'message_fold_tarock' | translate: { count: p.tarockFoldCount } }}</p>
          </ng-container>
        </ng-container>
        <h2 *ngIf="!actions[i - 1]?.action?.startsWith('call:') && action.action.startsWith('call:')">{{'message_calling' | translate}}</h2>
        <h2 *ngIf="!actions[i - 1]?.action?.startsWith('announce:') && action.action.startsWith('announce:')">{{'message_announcing' | translate}}</h2>
        <p
          *ngIf="action.action !== 'announce:passz' && gameTranslateService.translateAction(action.action) as translated"
          >
          {{game.players[action.seat].user.name}}: {{translated}}
        </p>
      </ng-template>
      <h2 *ngIf="!actions[actions.length - 1]?.action?.startsWith('bid:') && game.phase == 'bidding'">{{'message_bidding' | translate}}</h2>
      <h2 *ngIf="!actions[actions.length - 1]?.action?.startsWith('fold:') && game.phase == 'folding'">{{'message_folding' | translate}}</h2>
      <h2 *ngIf="!actions[actions.length - 1]?.action?.startsWith('call:') && game.phase == 'calling'">{{'message_calling' | translate}}</h2>
      <h2 *ngIf="!actions[actions.length - 1]?.action?.startsWith('announce:') && game.phase == 'announcing'">{{'message_announcing' | translate}}</h2>
    </div>
    <div id="chat" class="game-session-vertical-box">
      <h1>{{'chat' | translate}}</h1>
      <div id="chat-messages" appAutoScroll>
        <p *ngFor="let chat of chats"><span class="chat-username">{{chat.user.name}}:</span> {{chat.message}}</p>
      </div>
      <input placeholder="{{'chat_hint' | translate}}" [(ngModel)]="chatInputContent" (keyup.enter)="sendChat()"/>
    </div>
    <div id="card-table">
      <ng-template ngFor let-player [ngForOf]="playersRotated" let-i="index">
        <img
          *ngIf="trickTaking ? player.previousTrickCard : player.currentTrickCard as card"
          src="/assets/images/cards/{{card}}.png"
          [ngClass]="'played-card played-card-' + i"
          [ngStyle]="{'z-index': ((seat || 0) + i - (game.previousTrickWinner || 0) + 4) % 4}"
          />
        <p
          [ngClass]="{
            'player-name': true,
            'player-name-0': i === 0,
            'player-name-1': i === 1,
            'player-name-2': i === 2,
            'player-name-3': i === 3,
            'player-name-turn': player.turn,
            'player-name-caller': player.team === 'caller',
            'player-name-opponent': player.team === 'opponent'
          }"
          >
          {{player.user.name}}
        </p>
        <p
          *ngIf="gameTranslateService.translateAction(actionBubbleContent[i] || '') as text"
          [ngClass]="'action-bubble action-bubble-' + i"
          @actionBubbleAnimation
          >
          {{text}}
        </p>
      </ng-template>
      <table *ngIf="game.phase === 'end'">
        <tr class="statistics-table-team">
          <td colspan="2">{{'statistics.caller' | translate}}</td>
        </tr>
        <tr
          class="statistics-table-announcement"
          *ngFor="let announcementResult of game.statistics.callerAnnouncementResults"
          [ngClass]="{'statistics-announcement-failed': announcementResult.points < 0}"
          >
          <td>{{gameTranslateService.translateAnnouncement(announcementResult.announcement)}}</td>
          <td>{{announcementResult.points * (seat === null || game.players[seat].team === 'caller' ? 1 : -1)}}</td>
        </tr>
        <tr class="statistics-table-team">
          <td colspan="2">{{'statistics.opponent' | translate}}</td>
        </tr>
        <tr
          class="statistics-table-announcement"
          *ngFor="let announcementResult of game.statistics.opponentAnnouncementResults"
          [ngClass]="{'statistics-announcement-failed': announcementResult.points < 0}"
          >
          <td>{{gameTranslateService.translateAnnouncement(announcementResult.announcement)}}</td>
          <td>{{announcementResult.points * (seat === null || game.players[seat].team === 'caller' ? -1 : 1)}}</td>
        </tr>
        <tr class="statistics-table-sum">
          <td>{{'statistics.sum' | translate}}</td>
          <td>{{game.statistics.sumPoints}}</td>
        </tr>
      </table>
      <button
        id="button-new-game"
        *ngIf="['interrupted', 'end'].includes(game.phase) && seat !== null && game.players[seat].turn"
        (click)="executeAction('newgame:')"
        >
        {{'ready_for_new_game' | translate}}
      </button>
    </div>
    <div *ngIf="seat !== null" id="action-button-list">
      <button
        id="button-announce-passz"
        *ngIf="game.phase === 'announcing' && game.players[seat].turn"
        (click)="executeAction('announce:passz')"
        >
        {{'passz' | translate}}
      </button>
      <button
        *ngFor="let action of game.availableActions"
        (click)="executeAction(action)"
        >
        {{gameTranslateService.translateAction(action)}}
      </button>
    </div>
  </div>
  <div *ngIf="seat !== null" id="card-list">
    <button
      id="button-fold"
      *ngIf="game.phase === 'folding' && game.players[seat].turn"
      [disabled]="game.players[seat].cards.length - cardsToFold.length !== 9"
      (click)="foldCards()"
      >
      {{'fold' | translate}}
    </button>
    <button
      id="button-throw"
      *ngIf="game.canThrowCards"
      (click)="executeAction('throw:')"
      >
      {{'throw_cards' | translate}}
    </button>
    <img
      *ngFor="let card of game.players[seat].cards"
      [attr.data-card]="card"
      [ngClass]="{'card-selected': cardsToFold.includes(card)}"
      src="/assets/images/cards/{{card}}.png"
      (click)="clickCard(card)"/>
  </div>
</div>
