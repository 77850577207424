<div class="main-container">
  <div class="toolbar">
    <img
      width="40"
      alt="Tarokk Logo"
      src="assets/images/icon.png"
    />
    <span class="heavy-font">Tarokk</span>
    <span routerLink="/gameSessions" class="clickable-span">{{'toolbar.gameSessions' | translate}}</span>
    <div class="space"></div>
    <span *ngIf="!(user$ | async)" class="clickable-span" (click)="login()">{{'login' | translate}}</span>
    <ng-container *ngIf="(user$ | async) as user">
      <span>{{user.name}}</span>
      <img width="40" src={{user.imgUrl}}/>
      <span class="clickable-span" (click)="logout()">{{'logout' | translate}}</span>
    </ng-container>
    <mat-select [(ngModel)]="language" (ngModelChange)="updateLanguage()">
      <mat-option value="hu">HU</mat-option>
      <mat-option value="en">EN</mat-option>
    </mat-select>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
