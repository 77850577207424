<button (click)="createGame()">{{'new_game' | translate}}</button>
<div id=game-session-list>
  <div *ngFor="let gameSession of gameSessions" class="game-session">
    <p class="game-session-type">{{gameSession.type}}</p>
    <div class="game-session-player-list">
      <div *ngFor="let player of gameSession.players" class="game-session-player">
        <img [src]="'assets/images/' + (player.user.isOnline ? 'online' : 'offline') + '.png'"/>
        <p>{{player.user.name}}</p>
      </div>
    </div>
    <div class="game-session-button-container">
      <a routerLink="/gameSessions/{{gameSession.id}}"><button class="button-normal">{{(gameSession.state == 'lobby' ? 'lobby_enter' : (isMyGameSession(gameSession) ? 'join_game' : 'join_game_kibic')) | translate}}</button></a>
      <div><button *ngIf="isMyGameSession(gameSession)" class="button-normal button-red" (click)="deleteGameSession(gameSession.id)">{{'delete_game' | translate}}</button></div>
    </div>
  </div>
</div>
